.user-avatar {
  border-radius: 50%;
  color: #fff;
  line-height: 36px;
  width: 36px;
  height: 36px;
  text-align: center;
  background-color: #474789;
}

.info-cursor-text {
  cursor: text;
}

.user-avatar-image {
  border-radius: 50%;
  color: #fff;
  line-height: 36px;
  width: 36px;
  height: 36px;
  text-align: center;
  background-size: auto 36px;
  background-repeat: no-repeat;
  background-position: center;
}

.google-play-button-p {
  width: 215px;
  margin-left: 10px;
  text-align: center;
  color: #d0d0d0;
}
.google-play-button-img {
  width: 215px;
  margin-left: 10px;
}

.logo-footer-ga {
  position: relative;
  top: -2px;
}

.formInputSpinner {
  position: absolute;
  right: 44px;
  top: 15px;
  font-size: 40px;
}

.workflow-buttons {
  position: absolute;
  right: 50px;
  top: 15px;
}

.form .form-section {
  color: #00c0bd;
  line-height: 3rem;
  margin-bottom: 20px;
  border-bottom: 2px solid #6b6f82;
  font-weight: 500;
}

.label-control {
  text-align: right;
}

.react-datepicker-popper {
  z-index: 999999 !important;
}

@media only screen and (max-width: 650px) {
  #loginSubtitle {
    display: none;
  }

  #loginLogo {
    max-height: 85px;
    padding: 0px;
  }

  .label-control {
    text-align: left;
  }

  .row {
    display: block;
  }
  /* se usaba para ocultar opciones del menu en resoluciones chicas
	.menuItem{
		display: none;
	}
*/
  .ticketWidget {
    display: none;
  }
}

.dataTables_length .custom-select {
  background: none;
}
.searchRow {
  background-color: #fff !important;
}
table tbody tr td.panol {
  padding-left: 20px;
}
.panolRow {
  padding-left: 20px;
}
.col-centered {
  float: none;
  margin: 0 auto;
}

.cursor-pointer {
  cursor: pointer;
}

.pie-info-box {
  height: 25px;
  width: 25px;
}

.uniqueMaterialTable th,
td {
  border: none !important;
}
.addHeight {
  max-height: 45px !important;
}

.w-75-px {
  min-width: 75px !important;
}

.w-100-px {
  min-width: 100px !important;
}

.hidden-personal {
  display: none !important;
}

.table td.blankTableCell {
  background-color: #F4F5FA !important;
  border-bottom: 0px !important;
  padding-top: 15px !important;
}

.text-warning-personal {
  color: #FCD34D;
}

.text-success-personal {
  color: #36D297;
}

.select-personal-llantas .Select-menu-outer {
  max-height: 200px;
  margin-bottom: 15px !important;
}

.modal-form {
  margin-bottom: 150px !important;
}

.personal-no-focus:focus {
  outline: none !important;
  box-shadow: none !important;
}

.personal-no-focus:hover {
  outline: none !important;
  box-shadow: none !important;
}

.hover-reset {
  /* background-color: #1a1036; */
  line-height: 0;
}

.llantas-modulo {
  font-family: 'Poppins', sans-serif !important;
}

.btn-vec {
  text-align: center;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
}

.text-poppins-500 {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.swal2-modal {
  border-radius: 15px !important;
}
.resultados-busqueda-notif {
  border-radius: 3px;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resultados-busqueda-notif:hover {

  background-color: #61BD4F;
}

.flag-multilang {
  width: 3vh;
}

/* .dt-button-collection>.dropdown-menu {
  left: -150px !important;
} */

div.button-popover > div {
  z-index: 9999 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ruta-grilla-movil {
  position: relative;
}
.ruta-grilla-movil input {
  padding-left: 25px;
  border: 1px solid rgb(240, 230, 230);
  border-radius: 8px;
}
.ruta-grilla-movil i {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  pointer-events: none;
}

.ruta-grilla-movil input:focus {
  border-color: rgb(107, 104, 104);
  outline: none;
}

.ruta-grilla-movil-item-text  span {
  font-family: unset !important;
  font-size: 1.07rem;
}

.dt-info-icon {
  font-size: 18px;
}

th.dt-search-header {
  padding-top: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 10px !important;
}

th.dt-search-header input {
  padding-left: 5px !important;
}

.btn-dt-main {
  border: 0px;
}

.btn-dt-filter {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 2px;
  border-bottom-color: #0abfbc;
  border-bottom-style: solid;
  width: 90%;
}

.table th, .table td {
  border-bottom: 1px solid #e3ebf3 !important;
  border-top: 0px;
}

div.dt-buttons {
  clear: both;
  float: right;
}

.dt-buttons .btn {
  background-color: transparent;
  border-color: transparent;
  color: grey;
  font-size: 1.5rem;
  padding: 5px;
}

.dt-buttons .btn .la {
    font-size: 1.9rem;
}

.dt-buttons .btn:hover {
  color: #464855;
}

.dt-buttons .btn:active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: grey !important;
  font-size: 1.5rem;
  padding: 5px;
}

.status-green {
  width: 25px;
  padding-left: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid #28D094 !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid #e3ebf3 !important;
}

.status-red {
  width: 25px;
  padding: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid #FF4961 !important;
  border-bottom: 1px solid #e3ebf3 !important;
}

.status-orange {
  width: 25px;
  padding: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid #f77a1c !important;/*rgb(240, 166, 71);*/
  border-bottom: 1px solid #e3ebf3 !important;
}

.status-yellow {
  width: 25px;
  padding: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid rgb(241, 234, 127) !important;
  border-bottom: 1px solid #e3ebf3 !important;
}

.status-grey {
  width: 25px;
  padding: 0 !important;
  padding-right: 15px !important;
  border-left: 5px solid rgba(212, 210, 210, 0.781) !important;
  border-bottom: 1px solid #e3ebf3 !important;
}

.round-icon {
  line-height: initial;
  padding: 0.78rem 1.1rem;
  font-size: 24px;
  position: relative;
  top: -45px;
  margin-left: 10px;
}

.dt-icons {
  height: 30px;
}

.dt-user-avatar {
  border-radius: 50%;
  background-color: #474789;
  color: #fff;
  line-height: 40px;
  width:40px;
  height:40px;
  text-align: center;
}

.paleta-preventivo{
  background-color: #4dc4bc;
  color: #fff !important;
}

.paleta-correctivo{
  background-color: #67b7dc;
  color: #fff !important;
}

.paleta-vencimiento{
  background-color: #fe9657;
  color: #fff !important;
}

.paleta-en-taller{
  background-color: #7a7ae5;
  color: #fff !important;
}

.paleta-gestoria{
  background-color: #f7c361;
  color: #fff !important;
}

.paleta-combustible{
  background-color: #dc6967;
  color: #fff !important;
}

.paleta-infracciones{
  background-color: #616c6f;
  color: #fff !important;
}

.dt-user-avatar-image {
  border-radius: 50%;
  color: #fff;
  line-height: 40px;
  width:40px;
  height:40px;
  text-align: center;
  background-size: auto 40px;
  background-repeat: no-repeat;
  background-position: center;
}

#dataTable td {
  line-height: 40px;
}

#dataTable th {
  font-weight: 400;
}

.btn-dt-grid {
  border: 0;
  background-color: transparent !important;
}

.btn-dt-grid i {
  font-size: 1.3rem !important;
}

.btn-dt-grid .ft {
  font-size: 1.5rem !important;
}

.dt-info-icon {
  font-size: 1.4rem;
}

.dt-info-icon .la {
  font-size: 1.4em;
}

.table-hover tbody tr:hover {
  background-color: rgba(248, 240, 255, 0.5);
}

.dataTables_processing {
  z-index: 1000 !important;
}

.dataTable tbody input {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 2px !important;
  border-bottom-color: #0abfbc !important;
  border-bottom-style: solid !important;
  text-align: right !important;
}


.table th, .table td {
  padding: 0.75rem 1rem !important;
}

.btn-icon .fa-2x {
  font-size: 1.7em !important;
}

/* Fix Datatables con scroll */
div.dataTables_scrollBody > table.dataTable > thead > tr {
  display: none;
}
/* Fix Datatables con scroll */

.dataTableMovil td {
  vertical-align: middle;
}

.dropright .dropdown-toggle{
  text-align: left;
  background-color: transparent;
  border: none;
}

.dropright .dropdown-toggle i{
  color: #6B6F82;
  font-size: 16px;
}

.dropright .dropdown-toggle::after {
  border: none;
  display:none;
}

.dropright .dropdown-menu .dropdown-header{
  color: #ffffff;
  background-color: #241b3e;
}

.dropright .dropdown-menu .dropdown-item:hover{
  background-color: rgb(169, 169, 169);;
}

.p-0.embudo{
  padding: 0.75rem 0rem !important;
}

.button-embudo{
  padding-left: 0rem;
  cursor: pointer;
}

#dataTable td.center-column{
  text-align: center;
}

#dataTable td.custom-lineHeight {
  line-height: 15px;
}

/* CHECK THIS WITH TEAM */

/* #collection-import ~ div{
  left: -10px !important;
} */

#dataTable td.custom-padding-cero {
  padding: 0 !important;
}

#dataTable td.custom-td-llanta-impeccion-detalles-accion {
  padding-left: 7px !important;
  padding-right: 7px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

#dataTable td.custom-td-llanta-impeccion-detalles-puntos-medicion {
  padding: 0 !important;
  border: 2px solid #1a1036 !important;
}

#dataTable td.custom-td-llanta-impeccion-detalles {
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 2px solid #1a1036 !important;
}

#dataTable td.custom-td-llanta-asignacion-detalles {
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border: 2px solid #1a1036 !important;
}

.datatablesnew-status-icon-fa-fas-circle{
  font-size: 11px !important;
  position: relative;
  top: -6px;
}

.datatables-status-icon-fa-fas-circle{
  font-size: 11px !important;
  position: relative;
  top: 9px;
}

.dt-input-holder {
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border-bottom: 2px !important;
  border-bottom-color: #0abfbc !important;
  border-bottom-style: solid !important;
  text-align: right !important;
}

/* Chrome, Safari, Edge, Opera */
.input-no-arrows input::-webkit-outer-spin-button,.input-no-arrows input::-webkit-inner-spin-button{
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-no-arrows input{
  -moz-appearance:textfield;
}

.proximasTareas {
  height: 30px;
}

.proximasTareasRow {
  width: 100% !important;
}

.pointer {
  cursor: pointer;
}

.customCheckbox {
  accent-color: #34495e;
}

.rechazado-presupuesto-color {
  color: #FF4961 !important;
}

.aprobado-presupuesto-color {
  color: #28D094 !important;
}

.photo-upload div.filepicker {
  text-align: center;
  padding: 5px;
  background-color: #E1E1E1;
  border-radius: 180px;
  min-height: 240px;
  border: 2px dashed #C7C7C7;
  min-width: 240px;
  margin-left: 40px;
}

.photo-upload .dropzone .dz-preview.dz-image-preview {
  background: transparent;
}

.dropzone .dz-preview.dz-image-preview {
  background: transparent;
}

.photo-upload .dropzone .dz-preview .dz-image {
  border-radius: 180px;
  overflow: hidden;
  width: 200px;
  height: 200px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-image {
  object-fit: cover;
  background-color: #E1E1E1;
}

.dropzone .dz-image > img {
  height: 100%;
  filter: blur(0px) !important;
}

.photo-upload .filepicker-file-icon {
  position: relative;
  display: inline-block;
  margin: 5em .2em 2em 1em;
  padding-left: 40px;
  color: black;
}


.files-upload  div.filepicker {
  text-align: center;
  min-height: 120px;
  min-width: 120px;
  width: 100%;
  margin: 40px;
}

.dropzone .dz-preview .dz-remove a {
  cursor: pointer;
}
.fc-toolbar .fc-left {
		justify-self: start;

}

.fc-toolbar .fc-right {
		justify-self: end;

}

.fc-toolbar .fc-center {
	justify-self: center;
}

.fc-center h2{margin-left: 0 !important;}

.fc-toolbar {
    text-align: center;
		display: grid;
  	grid-template-columns: 1fr 1fr 1fr;
}

.fc .fc-toolbar>*>* {
    float: left;
    margin-left: .75em;
}

.fc-button-group {
    display: inline-block;
}

.fc-state-default.fc-corner-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.fc-state-default.fc-corner-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1em;
    margin-top: 1em;
}

.fc button {
	display : inline-block;
	font-weight : 400;
	text-align : center;
	white-space : nowrap;
	vertical-align : middle;
	-webkit-user-select : none;
			user-select : none;
	border : 1px solid transparent;
	padding : 0.75rem 1rem;
	font-size : 1rem;
	border-radius : 0.25rem;
	transition : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  @media screen and (prefers-reduced-motion: reduce) {
	.fc button {
			transition : none;
	}
  }
 .fc button:hover, .fc button:focus {
	text-decoration : none;
  }
  .fc button:focus, .fc button.focus {
	outline : 0;
	box-shadow : none;
  }
  .fc button.disabled, .fc button:disabled {
	opacity : 0.65;
  }
  .fc button:not(:disabled):not(.disabled) {
	cursor : pointer;
  }
  
  .fc button {
	color : #241c3e;
	background-color : transparent;
	background-image : none;
	border-color : #241c3e;
  }
  .fc button:hover {
	color : #FFFFFF;
	background-color : #241c3e;
	border-color : #241c3e;
  }
  .fc button:focus, .fc button.focus {
	box-shadow : 0 0 0 0.2rem rgba(36, 29, 62, 0.5);
  }
  .fc button.disabled, .fc button:disabled {
	color : #241c3e;
	background-color : transparent;
  }
  .fc button:not(:disabled):not(.disabled):active, .fc button:not(:disabled):not(.disabled).active, .fc .show > button.dropdown-toggle {
	color : #FFFFFF;
	background-color : #241c3e;
	border-color : #241c3e;
  }
  .fc button:not(:disabled):not(.disabled):active:focus, .fc button:not(:disabled):not(.disabled).active:focus, .fc .show > button.dropdown-toggle:focus {
	box-shadow : 0 0 0 0.2rem rgba(36, 29, 62, 0.5);
  }
  
  .fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
	border-color : #7C83EB;
  }
  
  th.fc-widget-header, .fc-basic-view td.fc-day-number, .fc-basic-view td.fc-week-number span {
	padding : 10px;
  }
  
  .fc-state-default {
	background-image : none;
	box-shadow : none;
  }
  
  .fc button {
	height : auto;
	outline : none;
	border-radius : 0;
	text-shadow : none;
  }
  
 .fc button:focus, .fc button:focus, .fc button.focus, .fc button.focus, .fc button:active:focus, .fc button:active:focus, .fc button:active.focus, .fc button:active.focus, .fc button.active:focus, .fc button.active:focus, .fc button.active.focus, .fc button.active.focus {
	outline : none;
  }
  
  .fc-day-grid-event {
	margin : 0 5px 5px 5px;
	padding : 4px 8px;
  }
  
  .fc-event {
	background : #241c3e;
	border : 1px solid #5059E5;
  }
  .fc-event span {
	font-size : 1rem;
	color : #FFFFFF;
  }
  
  .fc-time-grid-event .fc-title {
	color : #FFFFFF;
  }
  
  .fc-unthemed .fc-today {
	color : #FFFFFF;
	background : #241c3e;
  }
  
  .fc-unthemed .fc-divider, .fc-unthemed .fc-popover .fc-header {
	background : #E4EBF1;
  }
  
  .fc-popover .fc-header {
	padding : 10px 5px;
  }
  
  /* Styling for each event from Schedule */
  .fc-time-grid-event.fc-v-event.fc-event {
	border-radius : 4px;
	border : none;
	padding : 5px;
	opacity : 0.65;
	left : 5% !important;
	right : 5% !important;
  }
  
  /* Bolds the name of the event and inherits the font size */
  .fc-event {
	font-size : inherit !important;
	font-weight : bold !important;
	color : #FFFFFF !important;
  }
  .fc-event:hover {
	color : #FFFFFF !important;
  }
  
  /* Inherits background for each event from Schedule. */
  .fc-event .fc-bg {
	z-index : 1 !important;
	background : inherit !important;
	opacity : 0.25 !important;
  }
  
  /* Normal font weight for the time in each event */
  .fc-time-grid-event .fc-time {
	font-weight : normal !important;
  }
  
  /* Apply same opacity to all day events */
  .fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
	opacity : 0.65 !important;
	margin-left : 12px !important;
	padding : 5px !important;
  }
  
  /* Apply same opacity to all day events */
  .fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
	opacity : 0.65 !important;
	margin-left : 12px !important;
	padding : 5px !important;
  }
  
  .fc-time-grid .fc-slats td {
	height : auto;
  }
  
  .fc-ltr .fc-axis {
	padding : 10px;
  }
  
  .fc-nonbusiness {
	background : #D7E0EA;
  }
  
  .fc-events-container {
	padding : 0 10px;
	border : 1px solid #A2B8CD;
	background : #F4F5FA;
	text-align : left;
  }
  
  .fc-events-container .fc-event {
	padding : 5px;
	margin : 10px 0;
	}
	
  .rbc-calendar .rbc-time-view {
	overflow: auto;
  }

	.react-datepicker-wrapper,
	.react-datepicker__input-container,
	.react-datepicker__input-container input {
		display: block;
		width: 100%;
	}
.bg-gradient-x-white .card-header, .bg-gradient-x-white .card-footer, .bg-gradient-y-white .card-header, .bg-gradient-y-white .card-footer, .bg-gradient-directional-white .card-header, .bg-gradient-directional-white .card-footer, .bg-gradient-radial-white .card-header, .bg-gradient-radial-white .card-footer, .bg-gradient-striped-white .card-header, .bg-gradient-striped-white .card-footer, .bg-gradient-x2-white .card-header, .bg-gradient-x2-white .card-footer, .bg-gradient-y2-white .card-header, .bg-gradient-y2-white .card-footer {
  background-color: transparent; }

.bg-gradient-x-black .card-header, .bg-gradient-x-black .card-footer, .bg-gradient-y-black .card-header, .bg-gradient-y-black .card-footer, .bg-gradient-directional-black .card-header, .bg-gradient-directional-black .card-footer, .bg-gradient-radial-black .card-header, .bg-gradient-radial-black .card-footer, .bg-gradient-striped-black .card-header, .bg-gradient-striped-black .card-footer, .bg-gradient-x2-black .card-header, .bg-gradient-x2-black .card-footer, .bg-gradient-y2-black .card-header, .bg-gradient-y2-black .card-footer {
  background-color: transparent; }

.bg-gradient-x-primary {
  background-image: linear-gradient(to right, #535BE2 0%, #949AEF 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-primary {
  background-image: linear-gradient(to bottom, #535BE2 0%, #949AEF 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-primary {
  background-image: linear-gradient(45deg, #535BE2, #949AEF);
  background-repeat: repeat-x; }

.bg-gradient-x2-primary {
  background-image: linear-gradient(to right, #949AEF, #666EE8 50%, #B3B7F4);
  background-repeat: no-repeat; }

.bg-gradient-y2-primary {
  background-image: linear-gradient(#949AEF, #666EE8 50%, #B3B7F4);
  background-repeat: no-repeat; }

.bg-gradient-radial-primary {
  background-image: radial-gradient(circle, #535BE2, #949AEF);
  background-repeat: no-repeat; }

.bg-gradient-striped-primary {
  background-image: linear-gradient(45deg, #B3B7F4 25%, transparent 25%, transparent 50%, #B3B7F4 50%, #B3B7F4 75%, transparent 75%, transparent); }

.bg-gradient-x-primary .card-header, .bg-gradient-x-primary .card-footer, .bg-gradient-y-primary .card-header, .bg-gradient-y-primary .card-footer, .bg-gradient-directional-primary .card-header, .bg-gradient-directional-primary .card-footer, .bg-gradient-radial-primary .card-header, .bg-gradient-radial-primary .card-footer, .bg-gradient-striped-primary .card-header, .bg-gradient-striped-primary .card-footer, .bg-gradient-x2-primary .card-header, .bg-gradient-x2-primary .card-footer, .bg-gradient-y2-primary .card-header, .bg-gradient-y2-primary .card-footer {
  background-color: transparent; }

.bg-gradient-x-success {
  background-image: linear-gradient(to right, #1EC481 0%, #69DEB4 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-success {
  background-image: linear-gradient(to bottom, #1EC481 0%, #69DEB4 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-success {
  background-image: linear-gradient(45deg, #1EC481, #69DEB4);
  background-repeat: repeat-x; }

.bg-gradient-x2-success {
  background-image: linear-gradient(to right, #69DEB4, #28D094 50%, #94E8CA);
  background-repeat: no-repeat; }

.bg-gradient-y2-success {
  background-image: linear-gradient(#69DEB4, #28D094 50%, #94E8CA);
  background-repeat: no-repeat; }

.bg-gradient-radial-success {
  background-image: radial-gradient(circle, #1EC481, #69DEB4);
  background-repeat: no-repeat; }

.bg-gradient-striped-success {
  background-image: linear-gradient(45deg, #94E8CA 25%, transparent 25%, transparent 50%, #94E8CA 50%, #94E8CA 75%, transparent 75%, transparent); }

.bg-gradient-x-success .card-header, .bg-gradient-x-success .card-footer, .bg-gradient-y-success .card-header, .bg-gradient-y-success .card-footer, .bg-gradient-directional-success .card-header, .bg-gradient-directional-success .card-footer, .bg-gradient-radial-success .card-header, .bg-gradient-radial-success .card-footer, .bg-gradient-striped-success .card-header, .bg-gradient-striped-success .card-footer, .bg-gradient-x2-success .card-header, .bg-gradient-x2-success .card-footer, .bg-gradient-y2-success .card-header, .bg-gradient-y2-success .card-footer {
  background-color: transparent; }

.bg-gradient-x-info {
  background-image: linear-gradient(to right, #168DEE 0%, #62BCF6 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-info {
  background-image: linear-gradient(to bottom, #168DEE 0%, #62BCF6 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-info {
  background-image: linear-gradient(45deg, #168DEE, #62BCF6);
  background-repeat: repeat-x; }

.bg-gradient-x2-info {
  background-image: linear-gradient(to right, #62BCF6, #1E9FF2 50%, #8FCFF9);
  background-repeat: no-repeat; }

.bg-gradient-y2-info {
  background-image: linear-gradient(#62BCF6, #1E9FF2 50%, #8FCFF9);
  background-repeat: no-repeat; }

.bg-gradient-radial-info {
  background-image: radial-gradient(circle, #168DEE, #62BCF6);
  background-repeat: no-repeat; }

.bg-gradient-striped-info {
  background-image: linear-gradient(45deg, #8FCFF9 25%, transparent 25%, transparent 50%, #8FCFF9 50%, #8FCFF9 75%, transparent 75%, transparent); }

.bg-gradient-x-info .card-header, .bg-gradient-x-info .card-footer, .bg-gradient-y-info .card-header, .bg-gradient-y-info .card-footer, .bg-gradient-directional-info .card-header, .bg-gradient-directional-info .card-footer, .bg-gradient-radial-info .card-header, .bg-gradient-radial-info .card-footer, .bg-gradient-striped-info .card-header, .bg-gradient-striped-info .card-footer, .bg-gradient-x2-info .card-header, .bg-gradient-x2-info .card-footer, .bg-gradient-y2-info .card-header, .bg-gradient-y2-info .card-footer {
  background-color: transparent; }

.bg-gradient-x-warning {
  background-image: linear-gradient(to right, #FF7E39 0%, #FFB280 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-warning {
  background-image: linear-gradient(to bottom, #FF7E39 0%, #FFB280 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-warning {
  background-image: linear-gradient(45deg, #FF7E39, #FFB280);
  background-repeat: repeat-x; }

.bg-gradient-x2-warning {
  background-image: linear-gradient(to right, #FFB280, #FF9149 50%, #FFC8A4);
  background-repeat: no-repeat; }

.bg-gradient-y2-warning {
  background-image: linear-gradient(#FFB280, #FF9149 50%, #FFC8A4);
  background-repeat: no-repeat; }

.bg-gradient-radial-warning {
  background-image: radial-gradient(circle, #FF7E39, #FFB280);
  background-repeat: no-repeat; }

.bg-gradient-striped-warning {
  background-image: linear-gradient(45deg, #FFC8A4 25%, transparent 25%, transparent 50%, #FFC8A4 50%, #FFC8A4 75%, transparent 75%, transparent); }

.bg-gradient-x-warning .card-header, .bg-gradient-x-warning .card-footer, .bg-gradient-y-warning .card-header, .bg-gradient-y-warning .card-footer, .bg-gradient-directional-warning .card-header, .bg-gradient-directional-warning .card-footer, .bg-gradient-radial-warning .card-header, .bg-gradient-radial-warning .card-footer, .bg-gradient-striped-warning .card-header, .bg-gradient-striped-warning .card-footer, .bg-gradient-x2-warning .card-header, .bg-gradient-x2-warning .card-footer, .bg-gradient-y2-warning .card-header, .bg-gradient-y2-warning .card-footer {
  background-color: transparent; }

.bg-gradient-x-danger {
  background-image: linear-gradient(to right, #FF394F 0%, #FF8090 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-danger {
  background-image: linear-gradient(to bottom, #FF394F 0%, #FF8090 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-danger {
  background-image: linear-gradient(45deg, #FF394F, #FF8090);
  background-repeat: repeat-x; }

.bg-gradient-x2-danger {
  background-image: linear-gradient(to right, #FF8090, #FF4961 50%, #FFA4B0);
  background-repeat: no-repeat; }

.bg-gradient-y2-danger {
  background-image: linear-gradient(#FF8090, #FF4961 50%, #FFA4B0);
  background-repeat: no-repeat; }

.bg-gradient-radial-danger {
  background-image: radial-gradient(circle, #FF394F, #FF8090);
  background-repeat: no-repeat; }

.bg-gradient-striped-danger {
  background-image: linear-gradient(45deg, #FFA4B0 25%, transparent 25%, transparent 50%, #FFA4B0 50%, #FFA4B0 75%, transparent 75%, transparent); }

.bg-gradient-x-danger .card-header, .bg-gradient-x-danger .card-footer, .bg-gradient-y-danger .card-header, .bg-gradient-y-danger .card-footer, .bg-gradient-directional-danger .card-header, .bg-gradient-directional-danger .card-footer, .bg-gradient-radial-danger .card-header, .bg-gradient-radial-danger .card-footer, .bg-gradient-striped-danger .card-header, .bg-gradient-striped-danger .card-footer, .bg-gradient-x2-danger .card-header, .bg-gradient-x2-danger .card-footer, .bg-gradient-y2-danger .card-header, .bg-gradient-y2-danger .card-footer {
  background-color: transparent; }

.bg-gradient-x-red {
  background-image: linear-gradient(to right, #D32F2F 0%, #E57373 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-red {
  background-image: linear-gradient(to bottom, #D32F2F 0%, #E57373 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-red {
  background-image: linear-gradient(45deg, #D32F2F, #E57373);
  background-repeat: repeat-x; }

.bg-gradient-x2-red {
  background-image: linear-gradient(to right, #E57373, #F44336 50%, #EF9A9A);
  background-repeat: no-repeat; }

.bg-gradient-y2-red {
  background-image: linear-gradient(#E57373, #F44336 50%, #EF9A9A);
  background-repeat: no-repeat; }

.bg-gradient-radial-red {
  background-image: radial-gradient(circle, #D32F2F, #E57373);
  background-repeat: no-repeat; }

.bg-gradient-striped-red {
  background-image: linear-gradient(45deg, #EF9A9A 25%, transparent 25%, transparent 50%, #EF9A9A 50%, #EF9A9A 75%, transparent 75%, transparent); }

.bg-gradient-x-red .card-header, .bg-gradient-x-red .card-footer, .bg-gradient-y-red .card-header, .bg-gradient-y-red .card-footer, .bg-gradient-directional-red .card-header, .bg-gradient-directional-red .card-footer, .bg-gradient-radial-red .card-header, .bg-gradient-radial-red .card-footer, .bg-gradient-striped-red .card-header, .bg-gradient-striped-red .card-footer, .bg-gradient-x2-red .card-header, .bg-gradient-x2-red .card-footer, .bg-gradient-y2-red .card-header, .bg-gradient-y2-red .card-footer {
  background-color: transparent; }

.bg-gradient-x-pink {
  background-image: linear-gradient(to right, #C2185B 0%, #F06292 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-pink {
  background-image: linear-gradient(to bottom, #C2185B 0%, #F06292 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-pink {
  background-image: linear-gradient(45deg, #C2185B, #F06292);
  background-repeat: repeat-x; }

.bg-gradient-x2-pink {
  background-image: linear-gradient(to right, #F06292, #E91E63 50%, #F48FB1);
  background-repeat: no-repeat; }

.bg-gradient-y2-pink {
  background-image: linear-gradient(#F06292, #E91E63 50%, #F48FB1);
  background-repeat: no-repeat; }

.bg-gradient-radial-pink {
  background-image: radial-gradient(circle, #C2185B, #F06292);
  background-repeat: no-repeat; }

.bg-gradient-striped-pink {
  background-image: linear-gradient(45deg, #F48FB1 25%, transparent 25%, transparent 50%, #F48FB1 50%, #F48FB1 75%, transparent 75%, transparent); }

.bg-gradient-x-pink .card-header, .bg-gradient-x-pink .card-footer, .bg-gradient-y-pink .card-header, .bg-gradient-y-pink .card-footer, .bg-gradient-directional-pink .card-header, .bg-gradient-directional-pink .card-footer, .bg-gradient-radial-pink .card-header, .bg-gradient-radial-pink .card-footer, .bg-gradient-striped-pink .card-header, .bg-gradient-striped-pink .card-footer, .bg-gradient-x2-pink .card-header, .bg-gradient-x2-pink .card-footer, .bg-gradient-y2-pink .card-header, .bg-gradient-y2-pink .card-footer {
  background-color: transparent; }

.bg-gradient-x-purple {
  background-image: linear-gradient(to right, #7B1FA2 0%, #BA68C8 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-purple {
  background-image: linear-gradient(to bottom, #7B1FA2 0%, #BA68C8 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-purple {
  background-image: linear-gradient(45deg, #7B1FA2, #BA68C8);
  background-repeat: repeat-x; }

.bg-gradient-x2-purple {
  background-image: linear-gradient(to right, #BA68C8, #9C27B0 50%, #CE93D8);
  background-repeat: no-repeat; }

.bg-gradient-y2-purple {
  background-image: linear-gradient(#BA68C8, #9C27B0 50%, #CE93D8);
  background-repeat: no-repeat; }

.bg-gradient-radial-purple {
  background-image: radial-gradient(circle, #7B1FA2, #BA68C8);
  background-repeat: no-repeat; }

.bg-gradient-striped-purple {
  background-image: linear-gradient(45deg, #CE93D8 25%, transparent 25%, transparent 50%, #CE93D8 50%, #CE93D8 75%, transparent 75%, transparent); }

.bg-gradient-x-purple .card-header, .bg-gradient-x-purple .card-footer, .bg-gradient-y-purple .card-header, .bg-gradient-y-purple .card-footer, .bg-gradient-directional-purple .card-header, .bg-gradient-directional-purple .card-footer, .bg-gradient-radial-purple .card-header, .bg-gradient-radial-purple .card-footer, .bg-gradient-striped-purple .card-header, .bg-gradient-striped-purple .card-footer, .bg-gradient-x2-purple .card-header, .bg-gradient-x2-purple .card-footer, .bg-gradient-y2-purple .card-header, .bg-gradient-y2-purple .card-footer {
  background-color: transparent; }

.bg-gradient-x-blue {
  background-image: linear-gradient(to right, #1976D2 0%, #64B5F6 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-blue {
  background-image: linear-gradient(to bottom, #1976D2 0%, #64B5F6 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-blue {
  background-image: linear-gradient(45deg, #1976D2, #64B5F6);
  background-repeat: repeat-x; }

.bg-gradient-x2-blue {
  background-image: linear-gradient(to right, #64B5F6, #2196F3 50%, #90CAF9);
  background-repeat: no-repeat; }

.bg-gradient-y2-blue {
  background-image: linear-gradient(#64B5F6, #2196F3 50%, #90CAF9);
  background-repeat: no-repeat; }

.bg-gradient-radial-blue {
  background-image: radial-gradient(circle, #1976D2, #64B5F6);
  background-repeat: no-repeat; }

.bg-gradient-striped-blue {
  background-image: linear-gradient(45deg, #90CAF9 25%, transparent 25%, transparent 50%, #90CAF9 50%, #90CAF9 75%, transparent 75%, transparent); }

.bg-gradient-x-blue .card-header, .bg-gradient-x-blue .card-footer, .bg-gradient-y-blue .card-header, .bg-gradient-y-blue .card-footer, .bg-gradient-directional-blue .card-header, .bg-gradient-directional-blue .card-footer, .bg-gradient-radial-blue .card-header, .bg-gradient-radial-blue .card-footer, .bg-gradient-striped-blue .card-header, .bg-gradient-striped-blue .card-footer, .bg-gradient-x2-blue .card-header, .bg-gradient-x2-blue .card-footer, .bg-gradient-y2-blue .card-header, .bg-gradient-y2-blue .card-footer {
  background-color: transparent; }

.bg-gradient-x-cyan {
  background-image: linear-gradient(to right, #0097A7 0%, #4DD0E1 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-cyan {
  background-image: linear-gradient(to bottom, #0097A7 0%, #4DD0E1 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-cyan {
  background-image: linear-gradient(45deg, #0097A7, #4DD0E1);
  background-repeat: repeat-x; }

.bg-gradient-x2-cyan {
  background-image: linear-gradient(to right, #4DD0E1, #00BCD4 50%, #80DEEA);
  background-repeat: no-repeat; }

.bg-gradient-y2-cyan {
  background-image: linear-gradient(#4DD0E1, #00BCD4 50%, #80DEEA);
  background-repeat: no-repeat; }

.bg-gradient-radial-cyan {
  background-image: radial-gradient(circle, #0097A7, #4DD0E1);
  background-repeat: no-repeat; }

.bg-gradient-striped-cyan {
  background-image: linear-gradient(45deg, #80DEEA 25%, transparent 25%, transparent 50%, #80DEEA 50%, #80DEEA 75%, transparent 75%, transparent); }

.bg-gradient-x-cyan .card-header, .bg-gradient-x-cyan .card-footer, .bg-gradient-y-cyan .card-header, .bg-gradient-y-cyan .card-footer, .bg-gradient-directional-cyan .card-header, .bg-gradient-directional-cyan .card-footer, .bg-gradient-radial-cyan .card-header, .bg-gradient-radial-cyan .card-footer, .bg-gradient-striped-cyan .card-header, .bg-gradient-striped-cyan .card-footer, .bg-gradient-x2-cyan .card-header, .bg-gradient-x2-cyan .card-footer, .bg-gradient-y2-cyan .card-header, .bg-gradient-y2-cyan .card-footer {
  background-color: transparent; }

.bg-gradient-x-teal {
  background-image: linear-gradient(to right, #00796B 0%, #4DB6AC 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-teal {
  background-image: linear-gradient(to bottom, #00796B 0%, #4DB6AC 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-teal {
  background-image: linear-gradient(45deg, #00796B, #4DB6AC);
  background-repeat: repeat-x; }

.bg-gradient-x2-teal {
  background-image: linear-gradient(to right, #4DB6AC, #009688 50%, #80CBC4);
  background-repeat: no-repeat; }

.bg-gradient-y2-teal {
  background-image: linear-gradient(#4DB6AC, #009688 50%, #80CBC4);
  background-repeat: no-repeat; }

.bg-gradient-radial-teal {
  background-image: radial-gradient(circle, #00796B, #4DB6AC);
  background-repeat: no-repeat; }

.bg-gradient-striped-teal {
  background-image: linear-gradient(45deg, #80CBC4 25%, transparent 25%, transparent 50%, #80CBC4 50%, #80CBC4 75%, transparent 75%, transparent); }

.bg-gradient-x-teal .card-header, .bg-gradient-x-teal .card-footer, .bg-gradient-y-teal .card-header, .bg-gradient-y-teal .card-footer, .bg-gradient-directional-teal .card-header, .bg-gradient-directional-teal .card-footer, .bg-gradient-radial-teal .card-header, .bg-gradient-radial-teal .card-footer, .bg-gradient-striped-teal .card-header, .bg-gradient-striped-teal .card-footer, .bg-gradient-x2-teal .card-header, .bg-gradient-x2-teal .card-footer, .bg-gradient-y2-teal .card-header, .bg-gradient-y2-teal .card-footer {
  background-color: transparent; }

.bg-gradient-x-yellow {
  background-image: linear-gradient(to right, #FBC02D 0%, #FFF176 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-yellow {
  background-image: linear-gradient(to bottom, #FBC02D 0%, #FFF176 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-yellow {
  background-image: linear-gradient(45deg, #FBC02D, #FFF176);
  background-repeat: repeat-x; }

.bg-gradient-x2-yellow {
  background-image: linear-gradient(to right, #FFF176, #FFEB3B 50%, #FFF59D);
  background-repeat: no-repeat; }

.bg-gradient-y2-yellow {
  background-image: linear-gradient(#FFF176, #FFEB3B 50%, #FFF59D);
  background-repeat: no-repeat; }

.bg-gradient-radial-yellow {
  background-image: radial-gradient(circle, #FBC02D, #FFF176);
  background-repeat: no-repeat; }

.bg-gradient-striped-yellow {
  background-image: linear-gradient(45deg, #FFF59D 25%, transparent 25%, transparent 50%, #FFF59D 50%, #FFF59D 75%, transparent 75%, transparent); }

.bg-gradient-x-yellow .card-header, .bg-gradient-x-yellow .card-footer, .bg-gradient-y-yellow .card-header, .bg-gradient-y-yellow .card-footer, .bg-gradient-directional-yellow .card-header, .bg-gradient-directional-yellow .card-footer, .bg-gradient-radial-yellow .card-header, .bg-gradient-radial-yellow .card-footer, .bg-gradient-striped-yellow .card-header, .bg-gradient-striped-yellow .card-footer, .bg-gradient-x2-yellow .card-header, .bg-gradient-x2-yellow .card-footer, .bg-gradient-y2-yellow .card-header, .bg-gradient-y2-yellow .card-footer {
  background-color: transparent; }

.bg-gradient-x-amber {
  background-image: linear-gradient(to right, #FFA000 0%, #FFD54F 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-amber {
  background-image: linear-gradient(to bottom, #FFA000 0%, #FFD54F 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-amber {
  background-image: linear-gradient(45deg, #FFA000, #FFD54F);
  background-repeat: repeat-x; }

.bg-gradient-x2-amber {
  background-image: linear-gradient(to right, #FFD54F, #FFC107 50%, #FFE082);
  background-repeat: no-repeat; }

.bg-gradient-y2-amber {
  background-image: linear-gradient(#FFD54F, #FFC107 50%, #FFE082);
  background-repeat: no-repeat; }

.bg-gradient-radial-amber {
  background-image: radial-gradient(circle, #FFA000, #FFD54F);
  background-repeat: no-repeat; }

.bg-gradient-striped-amber {
  background-image: linear-gradient(45deg, #FFE082 25%, transparent 25%, transparent 50%, #FFE082 50%, #FFE082 75%, transparent 75%, transparent); }

.bg-gradient-x-amber .card-header, .bg-gradient-x-amber .card-footer, .bg-gradient-y-amber .card-header, .bg-gradient-y-amber .card-footer, .bg-gradient-directional-amber .card-header, .bg-gradient-directional-amber .card-footer, .bg-gradient-radial-amber .card-header, .bg-gradient-radial-amber .card-footer, .bg-gradient-striped-amber .card-header, .bg-gradient-striped-amber .card-footer, .bg-gradient-x2-amber .card-header, .bg-gradient-x2-amber .card-footer, .bg-gradient-y2-amber .card-header, .bg-gradient-y2-amber .card-footer {
  background-color: transparent; }

.bg-gradient-x-blue-grey {
  background-image: linear-gradient(to right, #455A64 0%, #90A4AE 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-blue-grey {
  background-image: linear-gradient(to bottom, #455A64 0%, #90A4AE 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-blue-grey {
  background-image: linear-gradient(45deg, #455A64, #90A4AE);
  background-repeat: repeat-x; }

.bg-gradient-x2-blue-grey {
  background-image: linear-gradient(to right, #90A4AE, #607D8B 50%, #B0BEC5);
  background-repeat: no-repeat; }

.bg-gradient-y2-blue-grey {
  background-image: linear-gradient(#90A4AE, #607D8B 50%, #B0BEC5);
  background-repeat: no-repeat; }

.bg-gradient-radial-blue-grey {
  background-image: radial-gradient(circle, #455A64, #90A4AE);
  background-repeat: no-repeat; }

.bg-gradient-striped-blue-grey {
  background-image: linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent); }

.bg-gradient-x-blue-grey .card-header, .bg-gradient-x-blue-grey .card-footer, .bg-gradient-y-blue-grey .card-header, .bg-gradient-y-blue-grey .card-footer, .bg-gradient-directional-blue-grey .card-header, .bg-gradient-directional-blue-grey .card-footer, .bg-gradient-radial-blue-grey .card-header, .bg-gradient-radial-blue-grey .card-footer, .bg-gradient-striped-blue-grey .card-header, .bg-gradient-striped-blue-grey .card-footer, .bg-gradient-x2-blue-grey .card-header, .bg-gradient-x2-blue-grey .card-footer, .bg-gradient-y2-blue-grey .card-header, .bg-gradient-y2-blue-grey .card-footer {
  background-color: transparent; }

.bg-gradient-x-grey-blue {
  background-image: linear-gradient(to right, #404E67 0%, #6F85AD 100%);
  background-repeat: repeat-x; }

.bg-gradient-y-grey-blue {
  background-image: linear-gradient(to bottom, #404E67 0%, #6F85AD 100%);
  background-repeat: repeat-x; }

.bg-gradient-directional-grey-blue {
  background-image: linear-gradient(45deg, #404E67, #6F85AD);
  background-repeat: repeat-x; }

.bg-gradient-x2-grey-blue {
  background-image: linear-gradient(to right, #6F85AD, #1B2942 50%, #B0BEC5);
  background-repeat: no-repeat; }

.bg-gradient-y2-grey-blue {
  background-image: linear-gradient(#6F85AD, #1B2942 50%, #B0BEC5);
  background-repeat: no-repeat; }

.bg-gradient-radial-grey-blue {
  background-image: radial-gradient(circle, #404E67, #6F85AD);
  background-repeat: no-repeat; }

.bg-gradient-striped-grey-blue {
  background-image: linear-gradient(45deg, #B0BEC5 25%, transparent 25%, transparent 50%, #B0BEC5 50%, #B0BEC5 75%, transparent 75%, transparent); }

.bg-gradient-x-grey-blue .card-header, .bg-gradient-x-grey-blue .card-footer, .bg-gradient-y-grey-blue .card-header, .bg-gradient-y-grey-blue .card-footer, .bg-gradient-directional-grey-blue .card-header, .bg-gradient-directional-grey-blue .card-footer, .bg-gradient-radial-grey-blue .card-header, .bg-gradient-radial-grey-blue .card-footer, .bg-gradient-striped-grey-blue .card-header, .bg-gradient-striped-grey-blue .card-footer, .bg-gradient-x2-grey-blue .card-header, .bg-gradient-x2-grey-blue .card-footer, .bg-gradient-y2-grey-blue .card-header, .bg-gradient-y2-grey-blue .card-footer {
  background-color: transparent; }

.llantas-table table.dataTable > thead .sorting:before,
.llantas-table table.dataTable > thead .sorting_asc:before,
.llantas-table table.dataTable > thead .sorting_desc:before,
.llantas-table table.dataTable > thead .sorting_asc_disabled:before,
.llantas-table table.dataTable > thead .sorting_desc_disabled:before {
    font-size: 25px !important;
}

.llantas-table table.dataTable > thead .sorting:before,
.llantas-table table.dataTable > thead .sorting:after,
.llantas-table table.dataTable > thead .sorting_asc:before,
.llantas-table table.dataTable > thead .sorting_asc:after,
.llantas-table table.dataTable > thead .sorting_desc:before,
.llantas-table table.dataTable > thead .sorting_desc:after,
.llantas-table table.dataTable > thead .sorting_asc_disabled:before,
.llantas-table table.dataTable > thead .sorting_asc_disabled:after,
.llantas-table table.dataTable > thead .sorting_desc_disabled:before,
.llantas-table table.dataTable > thead .sorting_desc_disabled:after {
    bottom: 0.1em !important;
}

.llantas-table table.dataTable > thead .sorting:after,
.llantas-table table.dataTable > thead .sorting_asc:after,
.llantas-table table.dataTable > thead .sorting_desc:after,
.llantas-table table.dataTable > thead .sorting_asc_disabled:after,
.llantas-table table.dataTable > thead .sorting_desc_disabled:after {
    font-size: 25px !important;
}

.llantas-table table.dataTable > thead .sorting:before,
.llantas-table table.dataTable > thead .sorting:after,
.llantas-table table.dataTable > thead .sorting_asc:before,
.llantas-table table.dataTable > thead .sorting_asc:after,
.llantas-table table.dataTable > thead .sorting_desc:before,
.llantas-table table.dataTable > thead .sorting_desc:after,
.llantas-table table.dataTable > thead .sorting_asc_disabled:before,
.llantas-table table.dataTable > thead .sorting_asc_disabled:after,
.llantas-table table.dataTable > thead .sorting_desc_disabled:before,
.llantas-table table.dataTable > thead .sorting_desc_disabled:after {
    bottom: 0.1em !important;
}

.reemplazo-label-danger {
    font-size: 12px;
    font-weight: 600;
    color: #cd2026;
    white-space: normal;
}

.chat-application .app-content, .chat-application .content-right, .chat-application .content-wrapper, .chat-application .content-body {
  height: 100%; width: 100%; }

.chat-application .content-wrapper {
  padding: 0 !important; }

.chat-application .sidebar-left {
  border-right: 1px solid #E4E7ED;
  z-index: 999; }

.chat-application .chat-fixed-search {
  position: fixed;
  z-index: 999;
  background: #FFFFFF;
  width: 300px;
  border-bottom: 1px solid #E4E7ED; }

.chat-application .users-list-padding {
  padding-top: 83px;
  padding-bottom: 60px; }

.chat-application .chat-app-window {
  padding: 20px 30px;
  overflow-y: scroll;
  text-align: center;
  height: calc(100% - 112px);
  background-color: #fff; }

.chat-application .chat-app-form {
  position: relative;
  padding: 20px 10px;
  background-color: #edeef0;
  overflow: hidden; }

.chat-application .chats {
  padding: 0; }
  .chat-application .chats .chat-body {
    display: block;
    margin: 10px 30px 0 0;
    overflow: hidden; }
    .chat-application .chats .chat-body .chat-content {
      text-align: right;
      position: relative;
      display: block;
      float: right;
      padding: 8px 15px;
      margin: 0 20px 10px 0;
      clear: both;
      color: #fff;
      background-color: #1E9FF2;
      border-radius: 4px; }
      .chat-application .chats .chat-body .chat-content:before {
        position: absolute;
        top: 10px;
        right: -10px;
        width: 0;
        height: 0;
        content: '';
        border: 5px solid transparent;
        border-left-color: #1E9FF2; }
      .chat-application .chats .chat-body .chat-content + .chat-content:before {
        border-color: transparent; }
      .chat-application .chats .chat-body .chat-content p {
        margin: 0; }
  .chat-application .chats .chat-avatar {
    float: right; }
    .chat-application .chats .chat-avatar .avatar {
      width: 50px;
      margin-top: -10px; }
  .chat-application .chats .time {
    color: #BABFC7;
    font-size: 14px;
    text-align: center;
   }
  .chat-application .chats .chat-left .chat-avatar {
    float: left; }
  .chat-application .chats .chat-left .chat-body {
    margin-right: 0;
    margin-left: 30px; }
  .chat-application .chats .chat-left .chat-content {
    text-align: left;
    float: left;
    margin: 0 0 10px 20px;
    color: #6B6F82;
    background-color: #edeef0; }
    .chat-application .chats .chat-left .chat-content + .chat-content:before {
      border-color: transparent; }
    .chat-application .chats .chat-left .chat-content:before {
      right: auto;
      left: -10px;
      border-right-color: #edeef0;
      border-left-color: transparent; }

.chat-application .form-control-position {
  cursor: pointer; }
  .chat-application .form-control-position.control-position-right {
    right: 18px;
    top: 2px;
    cursor: pointer; }

@media (max-width: 767.98px) {
  .chat-application .chat-app-window {
    height: calc(100% - 132px); } }

.horizontal-layout.chat-application .app-content {
  height: calc(100% - 144px) !important;
  min-height: calc(100% - 144px) !important;
  margin-top: 0 !important; }
  .horizontal-layout.chat-application .app-content .chat-app-window {
    height: -webkit-calc(100% - 83px) !important; }


.chat-application .chats .chat {
  margin-bottom: 6px;
}

.chat-application {
  border: 1px solid #edeef0;
}
/* --------------------------------
Horizontal Timeline
-------------------------------- */
/*@-webkit-keyframes 'cd-enter-right' {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%); }
}

@-moz-keyframes 'cd-enter-right' {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%); }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%); } }

@keyframes 'cd-enter-right' {
  0% {
    opacity: 0;
    transform: translateX(100%); }
  100% {
    opacity: 1;
    transform: translateX(0%); } }

@-webkit-keyframes 'cd-enter-left' {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%); } }

@-moz-keyframes 'cd-enter-left' {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%); }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%); } }

@keyframes 'cd-enter-left' {
  0% {
    opacity: 0;
    transform: translateX(-100%); }
  100% {
    opacity: 1;
    transform: translateX(0%); } }*/

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  transition: opacity 0.2s; }
  .cd-horizontal-timeline ol, .cd-horizontal-timeline ul {
    list-style: none; }
  .cd-horizontal-timeline::before {
    content: 'mobile';
    display: none; }
  .cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto; }
  .cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden; }
    .cd-horizontal-timeline .events-wrapper::after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 20px;
      right: 0; }
    .cd-horizontal-timeline .events-wrapper::before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      height: 100%;
      width: 20px;
      left: 0; }
  .cd-horizontal-timeline .events {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 39px;
    height: 2px;
    background: #dfdfdf;
    transition: transform 0.4s; }
    .cd-horizontal-timeline .events a {
      position: absolute;
      bottom: 0;
      z-index: 2;
      text-align: center;
      font-size: 1.3rem;
      padding-bottom: 15px;
      color: #383838;
      transform: translateZ(0); }
      .cd-horizontal-timeline .events a::after {
        content: '';
        position: absolute;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        bottom: -5px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        border: 2px solid #dfdfdf;
        background-color: #f8f8f8;
        transition: background-color 0.3s, border-color 0.3s; }
    .cd-horizontal-timeline .events a.selected {
      pointer-events: none; }
      .cd-horizontal-timeline .events a.selected::after {
        background-color: #1E9FF2;
        border-color: #1E9FF2; }
    .cd-horizontal-timeline .events a.older-event::after {
      border-color: #1E9FF2; }
  .cd-horizontal-timeline .filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #1E9FF2;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.3s; }
  .cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    transition: height 0.4s; }
    .cd-horizontal-timeline .events-content li {
      position: absolute;
      z-index: 1;
      width: 100%;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      padding: 0 5%;
      opacity: 0;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out; }
      .cd-horizontal-timeline .events-content li > * {
        max-width: 800px;
        margin: 0 auto; }
    .cd-horizontal-timeline .events-content li.selected {
      position: relative;
      z-index: 2;
      opacity: 1;
      transform: translateX(0); }
    .cd-horizontal-timeline .events-content li.enter-right {
      animation-name: cd-enter-right; }
    .cd-horizontal-timeline .events-content li.leave-right {
      animation-name: cd-enter-right;
      animation-direction: reverse; }
    .cd-horizontal-timeline .events-content li.enter-left {
      animation-name: cd-enter-left; }
    .cd-horizontal-timeline .events-content li.leave-left {
      animation-name: cd-enter-left;
      animation-direction: reverse; }

.cd-horizontal-timeline.loaded {
  opacity: 1; }

.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #1E9FF2;
  border-color: #1E9FF2; }

.no-touch .cd-timeline-navigation a:hover {
  border-color: #1E9FF2; }

.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #dfdfdf; }

.cd-timeline-navigation a {
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  transition: border-color 0.3s; }
  .cd-timeline-navigation a::after {
    content: '';
    position: absolute;
    height: 16px;
    width: 16px;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
   /* background: url(../../images/svg/cd-arrow.svg) no-repeat 0 0; */
  }

.cd-timeline-navigation a.prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg); }

.cd-timeline-navigation a.next {
  right: 0; }

.cd-timeline-navigation a.inactive {
  cursor: not-allowed; }
  .cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px; }

@media only screen and (min-width: 1100px) {
  .cd-horizontal-timeline::before {
    content: 'desktop'; } }

@media only screen and (min-width: 768px) {
  .cd-horizontal-timeline .events-content h2 {
    font-size: 4rem;
    font-weight: 800; }
  .cd-horizontal-timeline .events-content em {
    font-size: 1.5rem; } }

/* Timeline */
.timeline {
  position: relative;
  display: block;
  margin: 0;
  padding: 10px 0;
  list-style: none; }
  .timeline:after {
    content: " ";
    display: table;
    clear: both; }
  .timeline:before {
    content: " ";
    display: table; }
  .timeline > li {
    list-style: none; }
    .timeline > li:nth-child(even) {
      float: left;
      clear: left; }
    .timeline > li:nth-child(odd) {
      float: right;
      clear: right; }
  .timeline .media {
    border-bottom: 1px solid #eee;
    font-size: 13px; }
    .timeline .media p {
      font-size: 13px; }
    .timeline .media:last-child {
      border-bottom: 0; }

.timeline-line + .timeline-item {
  margin-top: -20px; }

.timeline-group {
  display: block;
  position: relative;
  margin: 20px 0;
  text-align: center;
  float: none !important;
  z-index: 1; }

.timeline-poster {
  margin-top: -20px; }
  .timeline-poster .btn-link {
    color: #a1aab0; }
    .timeline-poster .btn-link:active {
      color: #3e5771; }
    .timeline-poster .btn-link:focus {
      color: #3e5771; }
    .timeline-poster .btn-link:hover {
      color: #3e5771; }
  .timeline-poster .btn-link.active {
    color: #3e5771; }

#timeline.timeline-center .timeline-line {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  background-color: #ddd;
  transform: translate(-50%, 0); }

#timeline.timeline-center .timeline-item {
  position: relative;
  display: inline-block;
  width: 50%;
  padding: 0 50px 35px; }
  #timeline.timeline-center .timeline-item:nth-child(even) {
    padding: 0px 35px 50px 0px; }
    #timeline.timeline-center .timeline-item:nth-child(even):after {
      content: '';
      position: absolute;
      right: 19px;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid #ccc;
      z-index: 1;
      right: 24px;
      top: 20px;
      border-left: 11px solid #fff; }
    #timeline.timeline-center .timeline-item:nth-child(even):before {
      content: '';
      position: absolute;
      right: 23px;
      top: 20px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 12px solid #ccc;
      z-index: 1; }
    #timeline.timeline-center .timeline-item:nth-child(even) > .timeline-badge {
      right: -20px; }
  #timeline.timeline-center .timeline-item:nth-child(odd) {
    padding: 0px 0px 50px 35px; }
    #timeline.timeline-center .timeline-item:nth-child(odd):after {
      content: '';
      position: absolute;
      left: 19px;
      top: 10px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      z-index: 1;
      left: 24px;
      top: 20px;
      border-right: 11px solid #fff; }
    #timeline.timeline-center .timeline-item:nth-child(odd):before {
      content: '';
      position: absolute;
      left: 23px;
      top: 20px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      z-index: 1; }
    #timeline.timeline-center .timeline-item:nth-child(odd) > .timeline-badge {
      left: -20px; }
  #timeline.timeline-center .timeline-item > .timeline-badge {
    position: absolute;
    top: 12px;
    z-index: 1; }
    #timeline.timeline-center .timeline-item > .timeline-badge > span {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      text-decoration: none;
      transition: all ease .3s; }
      #timeline.timeline-center .timeline-item > .timeline-badge > span i {
        color: #FFFFFF;
        font-size: 1.6rem;
        display: block;
        line-height: 40px;
        top: 0; }
      #timeline.timeline-center .timeline-item > .timeline-badge > span:active {
        transform: scale(1.1); }
      #timeline.timeline-center .timeline-item > .timeline-badge > span:focus {
        transform: scale(1.1); }
      #timeline.timeline-center .timeline-item > .timeline-badge > span:hover {
        transform: scale(1.1); }
    #timeline.timeline-center .timeline-item > .timeline-badge > a.active {
      transform: scale(1.1); }
  #timeline.timeline-center .timeline-item > .timeline-panel {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px; }
    #timeline.timeline-center .timeline-item > .timeline-panel:hover .timeline-actions {
      display: block; }

#timeline.timeline-right .timeline-line {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  width: 2px;
  background-color: #ddd;
  transform: translate(-50%, 0); }

#timeline.timeline-right .timeline-group {
  text-align: right; }

#timeline.timeline-right .timeline-item {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0px 43px 35px 0px; }
  #timeline.timeline-right .timeline-item:after {
    content: '';
    position: absolute;
    right: 33px;
    top: 21px;
    width: 0;
    height: 0;
    z-index: 1;
    border-top: 11px solid #F9FAFD;
    border-bottom: 11px solid #F9FAFD;
    border: 0;
    border-left: 11px solid #fff; }
  #timeline.timeline-right .timeline-item:before {
    content: '';
    position: absolute;
    right: 32px;
    top: 20px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid #fff;
    z-index: 1; }
  #timeline.timeline-right .timeline-item > .timeline-badge {
    right: -8px; }
  #timeline.timeline-right .timeline-item > .timeline-badge {
    position: absolute;
    top: 12px;
    z-index: 1; }
    #timeline.timeline-right .timeline-item > .timeline-badge > span {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      text-decoration: none;
      transition: all ease .3s; }
      #timeline.timeline-right .timeline-item > .timeline-badge > span i {
        color: #FFFFFF;
        font-size: 1.6rem;
        display: block;
        line-height: 40px;
        top: 0; }
      #timeline.timeline-right .timeline-item > .timeline-badge > span:active {
        transform: scale(1.1); }
      #timeline.timeline-right .timeline-item > .timeline-badge > span:focus {
        transform: scale(1.1); }
      #timeline.timeline-right .timeline-item > .timeline-badge > span:hover {
        transform: scale(1.1); }
    #timeline.timeline-right .timeline-item > .timeline-badge > a.active {
      transform: scale(1.1); }
  #timeline.timeline-right .timeline-item > .timeline-panel {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px; }
    #timeline.timeline-right .timeline-item > .timeline-panel:hover .timeline-actions {
      display: block; }

#timeline.timeline-right .timeline-item.block:nth-child(even):before {
  right: 8px;
  left: auto; }

#timeline.timeline-right .timeline-item.block:nth-child(even):after {
  right: 11px;
  left: auto; }

#timeline.timeline-right .timeline-item.block:nth-child(odd):after {
  right: 8px;
  right: auto; }

#timeline.timeline-left .timeline-line {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  width: 2px;
  background-color: #ddd;
  transform: translate(-50%, 0); }

#timeline.timeline-left .timeline-group {
  text-align: left; }

#timeline.timeline-left .timeline-item {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 0 0 43px; }
  #timeline.timeline-left .timeline-item:after {
    content: '';
    position: absolute;
    left: 33px;
    top: 21px;
    width: 0;
    height: 0;
    z-index: 1;
    border-top: 11px solid #F9FAFD;
    border-bottom: 11px solid #F9FAFD;
    border: 0;
    border-right: 11px solid #fff; }
  #timeline.timeline-left .timeline-item:before {
    content: '';
    position: absolute;
    left: 32px;
    top: 20px;
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-right: 12px solid #fff;
    z-index: 1; }
  #timeline.timeline-left .timeline-item > .timeline-badge {
    left: -8px; }
  #timeline.timeline-left .timeline-item > .timeline-badge {
    position: absolute;
    top: 12px;
    z-index: 1; }
    #timeline.timeline-left .timeline-item > .timeline-badge > span {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      text-decoration: none;
      transition: all ease .3s; }
      #timeline.timeline-left .timeline-item > .timeline-badge > span i {
        color: #FFFFFF;
        font-size: 1.6rem;
        display: block;
        line-height: 40px;
        top: 0; }
      #timeline.timeline-left .timeline-item > .timeline-badge > span:active {
        transform: scale(1.1); }
      #timeline.timeline-left .timeline-item > .timeline-badge > span:focus {
        transform: scale(1.1); }
      #timeline.timeline-left .timeline-item > .timeline-badge > span:hover {
        transform: scale(1.1); }
    #timeline.timeline-left .timeline-item > .timeline-badge > a.active {
      transform: scale(1.1); }
  #timeline.timeline-left .timeline-item > .timeline-panel {
    position: relative;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px; }
    #timeline.timeline-left .timeline-item > .timeline-panel:hover .timeline-actions {
      display: block; }

#timeline .timeline-item.block:nth-child(even) {
  width: 100% !important;
  margin-top: 5px; }
  #timeline .timeline-item.block:nth-child(even):after {
    left: 50%;
    right: auto;
    top: -11px;
    border: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    transform: translate(-50%, 0);
    /* top: -10px;
			border: 0;
			border-left: 11px solid $body-bg;
			border-right: 11px solid $body-bg;
			border-bottom: 11px solid #fff; */ }
  #timeline .timeline-item.block:nth-child(even):before {
    left: 50%;
    right: auto;
    top: -11px;
    border: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ccc;
    transform: translate(-50%, 0); }
  #timeline .timeline-item.block:nth-child(even) > .timeline-badge {
    top: -28px;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0); }

#timeline .timeline-item.block:nth-child(odd) {
  width: 100% !important;
  margin-top: 5px; }
  #timeline .timeline-item.block:nth-child(odd):after {
    left: 50%;
    right: auto;
    top: -11px;
    border: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ccc;
    transform: translate(-50%, 0);
    top: -10px;
    border: 0;
    border-left: 11px solid #F9FAFD;
    border-right: 11px solid #F9FAFD;
    border-bottom: 11px solid #fff; }
  #timeline .timeline-item.block:nth-child(odd):before {
    left: 50%;
    right: auto;
    top: -11px;
    border: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #ccc;
    transform: translate(-50%, 0); }
  #timeline .timeline-item.block:nth-child(odd) > .timeline-badge {
    top: -28px;
    left: 50%;
    right: auto;
    transform: translate(-50%, 0); }

.timeline-actions {
  display: none; }

.timeline-content {
  padding: 15px; }

.timeline-footer {
  padding: 15px;
  border-top: 1px solid #eee;
  background-color: #fbfcfc;
  border-radius: 0 0 5px 5px; }

.timeline-heading {
  padding: 15px;
  font-size: 20px; }

.timeline-liveliness {
  padding: 15px;
  border-top: 1px solid #eee;
  background-color: #fbfcfc; }

.timeline-heading + .timeline-content {
  padding-top: 0; }

.timeline-date {
  font-size: 14px;
  color: #aaa; }

.timeline-embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  .timeline-embed .embed-element {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .timeline-embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .timeline-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .timeline-embed object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.timeline-img {
  display: block;
  padding: 5px 0; }

.timeline-img.first {
  margin-right: -10px; }

.timeline-img.middle {
  margin-right: -10px;
  margin-left: -10px; }

.timeline-img.last {
  margin-left: -10px; }

.timeline-resume {
  border-top: 1px solid #eee;
  background-color: #fbfcfc; }

.timeline-avatar {
  margin-top: -2px;
  margin-right: 10px; }

.timeline-title {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1.42857;
  font-weight: 600;
  color: #3e5771;
  text-decoration: none; }
  .timeline-title > small {
    display: block;
    font-size: 12px;
    line-height: 1.5;
    color: #a1aab0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.wrapkit-content-rtl .timeline-avatar {
  margin-right: 0;
  margin-left: 10px; }

@media (min-width: 992px) {
  .timeline-item:nth-child(even) {
    padding: 0px 35px 50px 0px; }
  .timeline-item:nth-child(odd) {
    padding: 0 0px 50px 35px; } }

@media (max-width: 991.98px) {
  .timeline {
    padding-left: 15px; }
  .timeline-line {
    left: 15px; }
  .timeline-group {
    display: inline-block;
    margin-left: -22px; }
  .timeline-item {
    width: 100% !important;
    margin-top: 0 !important;
    padding-right: 10px; }
    .timeline-item:nth-child(even):after {
      left: 19px;
      top: 10px;
      border: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      transform: translate(0, 0);
      left: 40px;
      top: 21px;
      border-top: 11px solid #F9FAFD;
      border-bottom: 11px solid #F9FAFD;
      border-right: 11px solid #fff; }
    .timeline-item:nth-child(even):before {
      left: 39px;
      top: 20px;
      border: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      transform: translate(0, 0); }
    .timeline-item:nth-child(even) > .timeline-badge {
      top: 12px;
      left: -20px;
      right: auto;
      transform: translate(0, 0); }
    .timeline-item:nth-child(odd):after {
      left: 19px;
      top: 10px;
      border: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      transform: translate(0, 0);
      left: 40px;
      top: 21px;
      border-top: 11px solid #F9FAFD;
      border-bottom: 11px solid #F9FAFD;
      border-right: 11px solid #fff; }
    .timeline-item:nth-child(odd):before {
      left: 39px;
      top: 20px;
      border: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      transform: translate(0, 0); }
    .timeline-item:nth-child(odd) > .timeline-badge {
      top: 12px;
      left: -20px;
      right: auto;
      transform: translate(0, 0); }
  .timeline-item.block:nth-child(even) {
    padding-bottom: 0; }
    .timeline-item.block:nth-child(even):after {
      left: 19px;
      top: 10px;
      border: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      transform: translate(0, 0);
      left: 20px;
      top: 11px;
      border-top: 11px solid #F9FAFD;
      border-bottom: 11px solid #F9FAFD;
      border-right: 11px solid #fff; }
    .timeline-item.block:nth-child(even):before {
      left: 19px;
      top: 10px;
      border: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      transform: translate(0, 0); }
    .timeline-item.block:nth-child(even) > .timeline-badge {
      top: 12px;
      left: -6px;
      right: auto;
      transform: translate(0, 0); }
  .timeline-item.block:nth-child(odd) {
    padding-bottom: 0; }
    .timeline-item.block:nth-child(odd):after {
      left: 19px;
      top: 10px;
      border: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      transform: translate(0, 0);
      left: 20px;
      top: 11px;
      border-top: 11px solid #F9FAFD;
      border-bottom: 11px solid #F9FAFD;
      border-right: 11px solid #fff; }
    .timeline-item.block:nth-child(odd):before {
      left: 19px;
      top: 10px;
      border: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #ccc;
      transform: translate(0, 0); }
    .timeline-item.block:nth-child(odd) > .timeline-badge {
      top: 12px;
      left: -6px;
      right: auto;
      transform: translate(0, 0); } }

@media (max-width: 991.98px) {
  #timeline.timeline-wrapper .timeline {
    padding: 0; }
    #timeline.timeline-wrapper .timeline .card-body {
      text-align: left; }
  #timeline.timeline-wrapper .timeline-line {
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background-color: #ddd;
    transform: translate(-50%, 0); }
  #timeline.timeline-wrapper .timeline-group {
    display: block;
    margin-left: -6px;
    text-align: center; }
  #timeline.timeline-wrapper .timeline-item {
    display: block;
    padding: 20px 0 20px !important; }
    #timeline.timeline-wrapper .timeline-item:nth-child(even) {
      float: none;
      clear: both; }
      #timeline.timeline-wrapper .timeline-item:nth-child(even):after {
        display: none; }
      #timeline.timeline-wrapper .timeline-item:nth-child(even):before {
        display: none; }
    #timeline.timeline-wrapper .timeline-item:nth-child(odd) {
      float: none;
      clear: both; }
      #timeline.timeline-wrapper .timeline-item:nth-child(odd):after {
        display: none; }
      #timeline.timeline-wrapper .timeline-item:nth-child(odd):before {
        display: none; }
    #timeline.timeline-wrapper .timeline-item > .timeline-badge {
      top: 0px !important;
      left: 45% !important; }
  #timeline.timeline-wrapper .timeline-item.block:nth-child(even) {
    float: none;
    clear: both; }
    #timeline.timeline-wrapper .timeline-item.block:nth-child(even):after {
      display: none; }
    #timeline.timeline-wrapper .timeline-item.block:nth-child(even):before {
      display: none; }
  #timeline.timeline-wrapper .timeline-item.block:nth-child(odd) {
    float: none;
    clear: both; }
    #timeline.timeline-wrapper .timeline-item.block:nth-child(odd):after {
      display: none; }
    #timeline.timeline-wrapper .timeline-item.block:nth-child(odd):before {
      display: none; }
  #timeline.timeline-wrapper .timeline-item.timeline-poster > .timeline-badge {
    display: none; } }

@media (max-width: 575.98px) {
  .timeline-item > .timeline-badge {
    top: 0px !important;
    left: 44% !important; } }


.card-history {
  margin-bottom: 1.875rem !important;
  border: none !important;
  box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
}

.modal-grey {
  background-color: #F4F5FA;
}

.status-change:before {
  border-right-color: #99d0e9 !important;
}

.status-change .card-header {
  background-color: #99d0e9;
}
.radioButtons>label{
  color:white;
  font-weight: bold;
  margin-left: 5px;
  border-color : #2e2e2e;
}

.radio-red{
  background-color:#FF4961 !important;
}

.radio-yellow{
  background-color:#F2E97F !important;
}

.radio-green{
  background-color:#27D094 !important;
}

/* Círculos de colores numerados */
.number-span-red {
  background: #FF4961;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: normal;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.8em; 
}

.number-span-yellow {
  background: #F2E97F;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.8em; 
}

.number-span-green {
  background: #27D094;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.8em; 
}
.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }
  
.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
