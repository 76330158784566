.user-avatar {
  border-radius: 50%;
  color: #fff;
  line-height: 36px;
  width: 36px;
  height: 36px;
  text-align: center;
  background-color: #474789;
}

.info-cursor-text {
  cursor: text;
}

.user-avatar-image {
  border-radius: 50%;
  color: #fff;
  line-height: 36px;
  width: 36px;
  height: 36px;
  text-align: center;
  background-size: auto 36px;
  background-repeat: no-repeat;
  background-position: center;
}

.google-play-button-p {
  width: 215px;
  margin-left: 10px;
  text-align: center;
  color: #d0d0d0;
}
.google-play-button-img {
  width: 215px;
  margin-left: 10px;
}

.logo-footer-ga {
  position: relative;
  top: -2px;
}

.formInputSpinner {
  position: absolute;
  right: 44px;
  top: 15px;
  font-size: 40px;
}

.workflow-buttons {
  position: absolute;
  right: 50px;
  top: 15px;
}

.form .form-section {
  color: #00c0bd;
  line-height: 3rem;
  margin-bottom: 20px;
  border-bottom: 2px solid #6b6f82;
  font-weight: 500;
}

.label-control {
  text-align: right;
}

.react-datepicker-popper {
  z-index: 999999 !important;
}

@media only screen and (max-width: 650px) {
  #loginSubtitle {
    display: none;
  }

  #loginLogo {
    max-height: 85px;
    padding: 0px;
  }

  .label-control {
    text-align: left;
  }

  .row {
    display: block;
  }
  /* se usaba para ocultar opciones del menu en resoluciones chicas
	.menuItem{
		display: none;
	}
*/
  .ticketWidget {
    display: none;
  }
}

.dataTables_length .custom-select {
  background: none;
}
.searchRow {
  background-color: #fff !important;
}
table tbody tr td.panol {
  padding-left: 20px;
}
.panolRow {
  padding-left: 20px;
}
.col-centered {
  float: none;
  margin: 0 auto;
}

.cursor-pointer {
  cursor: pointer;
}

.pie-info-box {
  height: 25px;
  width: 25px;
}

.uniqueMaterialTable th,
td {
  border: none !important;
}
.addHeight {
  max-height: 45px !important;
}

.w-75-px {
  min-width: 75px !important;
}

.w-100-px {
  min-width: 100px !important;
}

.hidden-personal {
  display: none !important;
}

.table td.blankTableCell {
  background-color: #F4F5FA !important;
  border-bottom: 0px !important;
  padding-top: 15px !important;
}

.text-warning-personal {
  color: #FCD34D;
}

.text-success-personal {
  color: #36D297;
}

.select-personal-llantas .Select-menu-outer {
  max-height: 200px;
  margin-bottom: 15px !important;
}

.modal-form {
  margin-bottom: 150px !important;
}

.personal-no-focus:focus {
  outline: none !important;
  box-shadow: none !important;
}

.personal-no-focus:hover {
  outline: none !important;
  box-shadow: none !important;
}

.hover-reset {
  /* background-color: #1a1036; */
  line-height: 0;
}

.llantas-modulo {
  font-family: 'Poppins', sans-serif !important;
}

.btn-vec {
  text-align: center;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Poppins', sans-serif !important;
}

.text-poppins-500 {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

.swal2-modal {
  border-radius: 15px !important;
}
.resultados-busqueda-notif {
  border-radius: 3px;
  height: 100%;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resultados-busqueda-notif:hover {

  background-color: #61BD4F;
}

.flag-multilang {
  width: 3vh;
}

/* .dt-button-collection>.dropdown-menu {
  left: -150px !important;
} */

div.button-popover > div {
  z-index: 9999 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ruta-grilla-movil {
  position: relative;
}
.ruta-grilla-movil input {
  padding-left: 25px;
  border: 1px solid rgb(240, 230, 230);
  border-radius: 8px;
}
.ruta-grilla-movil i {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #ccc;
  pointer-events: none;
}

.ruta-grilla-movil input:focus {
  border-color: rgb(107, 104, 104);
  outline: none;
}

.ruta-grilla-movil-item-text  span {
  font-family: unset !important;
  font-size: 1.07rem;
}
