.llantas-table table.dataTable > thead .sorting:before,
.llantas-table table.dataTable > thead .sorting_asc:before,
.llantas-table table.dataTable > thead .sorting_desc:before,
.llantas-table table.dataTable > thead .sorting_asc_disabled:before,
.llantas-table table.dataTable > thead .sorting_desc_disabled:before {
    font-size: 25px !important;
}

.llantas-table table.dataTable > thead .sorting:before,
.llantas-table table.dataTable > thead .sorting:after,
.llantas-table table.dataTable > thead .sorting_asc:before,
.llantas-table table.dataTable > thead .sorting_asc:after,
.llantas-table table.dataTable > thead .sorting_desc:before,
.llantas-table table.dataTable > thead .sorting_desc:after,
.llantas-table table.dataTable > thead .sorting_asc_disabled:before,
.llantas-table table.dataTable > thead .sorting_asc_disabled:after,
.llantas-table table.dataTable > thead .sorting_desc_disabled:before,
.llantas-table table.dataTable > thead .sorting_desc_disabled:after {
    bottom: 0.1em !important;
}

.llantas-table table.dataTable > thead .sorting:after,
.llantas-table table.dataTable > thead .sorting_asc:after,
.llantas-table table.dataTable > thead .sorting_desc:after,
.llantas-table table.dataTable > thead .sorting_asc_disabled:after,
.llantas-table table.dataTable > thead .sorting_desc_disabled:after {
    font-size: 25px !important;
}

.llantas-table table.dataTable > thead .sorting:before,
.llantas-table table.dataTable > thead .sorting:after,
.llantas-table table.dataTable > thead .sorting_asc:before,
.llantas-table table.dataTable > thead .sorting_asc:after,
.llantas-table table.dataTable > thead .sorting_desc:before,
.llantas-table table.dataTable > thead .sorting_desc:after,
.llantas-table table.dataTable > thead .sorting_asc_disabled:before,
.llantas-table table.dataTable > thead .sorting_asc_disabled:after,
.llantas-table table.dataTable > thead .sorting_desc_disabled:before,
.llantas-table table.dataTable > thead .sorting_desc_disabled:after {
    bottom: 0.1em !important;
}

.reemplazo-label-danger {
    font-size: 12px;
    font-weight: 600;
    color: #cd2026;
    white-space: normal;
}
