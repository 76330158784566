
.photo-upload div.filepicker {
  text-align: center;
  padding: 5px;
  background-color: #E1E1E1;
  border-radius: 180px;
  min-height: 240px;
  border: 2px dashed #C7C7C7;
  min-width: 240px;
  margin-left: 40px;
}

.photo-upload .dropzone .dz-preview.dz-image-preview {
  background: transparent;
}

.dropzone .dz-preview.dz-image-preview {
  background: transparent;
}

.photo-upload .dropzone .dz-preview .dz-image {
  border-radius: 180px;
  overflow: hidden;
  width: 200px;
  height: 200px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-image {
  object-fit: cover;
  background-color: #E1E1E1;
}

.dropzone .dz-image > img {
  height: 100%;
  -webkit-filter: blur(0px) !important;
  filter: blur(0px) !important;
}

.photo-upload .filepicker-file-icon {
  position: relative;
  display: inline-block;
  margin: 5em .2em 2em 1em;
  padding-left: 40px;
  color: black;
}


.files-upload  div.filepicker {
  text-align: center;
  min-height: 120px;
  min-width: 120px;
  width: 100%;
  margin: 40px;
}

.dropzone .dz-preview .dz-remove a {
  cursor: pointer;
}