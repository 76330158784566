.radioButtons>label{
  color:white;
  font-weight: bold;
  margin-left: 5px;
  border-color : #2e2e2e;
}

.radio-red{
  background-color:#FF4961 !important;
}

.radio-yellow{
  background-color:#F2E97F !important;
}

.radio-green{
  background-color:#27D094 !important;
}

/* Círculos de colores numerados */
.number-span-red {
  background: #FF4961;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: normal;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.8em; 
}

.number-span-yellow {
  background: #F2E97F;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.8em; 
}

.number-span-green {
  background: #27D094;
   border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 15px;
  text-align: center;
  width: 1.8em; 
}