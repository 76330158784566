.fc-toolbar .fc-left {
		justify-self: start;

}

.fc-toolbar .fc-right {
		justify-self: end;

}

.fc-toolbar .fc-center {
	justify-self: center;
}

.fc-center h2{margin-left: 0 !important;}

.fc-toolbar {
    text-align: center;
		display: grid;
  	grid-template-columns: 1fr 1fr 1fr;
}

.fc .fc-toolbar>*>* {
    float: left;
    margin-left: .75em;
}

.fc-button-group {
    display: inline-block;
}

.fc-state-default.fc-corner-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.fc-state-default.fc-corner-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1em;
    margin-top: 1em;
}

.fc button {
	display : inline-block;
	font-weight : 400;
	text-align : center;
	white-space : nowrap;
	vertical-align : middle;
	-webkit-user-select : none;
	   -moz-user-select : none;
		-ms-user-select : none;
			user-select : none;
	border : 1px solid transparent;
	padding : 0.75rem 1rem;
	font-size : 1rem;
	border-radius : 0.25rem;
	-webkit-transition : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
			transition : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
		 -o-transition : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	   -moz-transition : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
			transition : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
			transition : color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }
  @media screen and (prefers-reduced-motion: reduce) {
	.fc button {
	  -webkit-transition : none;
		   -o-transition : none;
		 -moz-transition : none;
			  transition : none;
	}
  }
 .fc button:hover, .fc button:focus {
	text-decoration : none;
  }
  .fc button:focus, .fc button.focus {
	outline : 0;
	-webkit-box-shadow : none;
			box-shadow : none;
  }
  .fc button.disabled, .fc button:disabled {
	opacity : 0.65;
  }
  .fc button:not(:disabled):not(.disabled) {
	cursor : pointer;
  }
  
  .fc button {
	color : #241c3e;
	background-color : transparent;
	background-image : none;
	border-color : #241c3e;
  }
  .fc button:hover {
	color : #FFFFFF;
	background-color : #241c3e;
	border-color : #241c3e;
  }
  .fc button:focus, .fc button.focus {
	-webkit-box-shadow : 0 0 0 0.2rem rgba(36, 29, 62, 0.5);
					box-shadow : 0 0 0 0.2rem rgba(36, 29, 62, 0.5);
  }
  .fc button.disabled, .fc button:disabled {
	color : #241c3e;
	background-color : transparent;
  }
  .fc button:not(:disabled):not(.disabled):active, .fc button:not(:disabled):not(.disabled).active, .fc .show > button.dropdown-toggle {
	color : #FFFFFF;
	background-color : #241c3e;
	border-color : #241c3e;
  }
  .fc button:not(:disabled):not(.disabled):active:focus, .fc button:not(:disabled):not(.disabled).active:focus, .fc .show > button.dropdown-toggle:focus {
	-webkit-box-shadow : 0 0 0 0.2rem rgba(36, 29, 62, 0.5);
					box-shadow : 0 0 0 0.2rem rgba(36, 29, 62, 0.5);
  }
  
  .fc-unthemed .fc-content, .fc-unthemed .fc-divider, .fc-unthemed .fc-popover, .fc-unthemed .fc-row, .fc-unthemed tbody, .fc-unthemed td, .fc-unthemed th, .fc-unthemed thead {
	border-color : #7C83EB;
  }
  
  th.fc-widget-header, .fc-basic-view td.fc-day-number, .fc-basic-view td.fc-week-number span {
	padding : 10px;
  }
  
  .fc-state-default {
	background-image : none;
	-webkit-box-shadow : none;
			box-shadow : none;
  }
  
  .fc button {
	height : auto;
	outline : none;
	border-radius : 0;
	text-shadow : none;
  }
  
 .fc button:focus, .fc button:focus, .fc button.focus, .fc button.focus, .fc button:active:focus, .fc button:active:focus, .fc button:active.focus, .fc button:active.focus, .fc button.active:focus, .fc button.active:focus, .fc button.active.focus, .fc button.active.focus {
	outline : none;
  }
  
  .fc-day-grid-event {
	margin : 0 5px 5px 5px;
	padding : 4px 8px;
  }
  
  .fc-event {
	background : #241c3e;
	border : 1px solid #5059E5;
  }
  .fc-event span {
	font-size : 1rem;
	color : #FFFFFF;
  }
  
  .fc-time-grid-event .fc-title {
	color : #FFFFFF;
  }
  
  .fc-unthemed .fc-today {
	color : #FFFFFF;
	background : #241c3e;
  }
  
  .fc-unthemed .fc-divider, .fc-unthemed .fc-popover .fc-header {
	background : #E4EBF1;
  }
  
  .fc-popover .fc-header {
	padding : 10px 5px;
  }
  
  /* Styling for each event from Schedule */
  .fc-time-grid-event.fc-v-event.fc-event {
	border-radius : 4px;
	border : none;
	padding : 5px;
	opacity : 0.65;
	left : 5% !important;
	right : 5% !important;
  }
  
  /* Bolds the name of the event and inherits the font size */
  .fc-event {
	font-size : inherit !important;
	font-weight : bold !important;
	color : #FFFFFF !important;
  }
  .fc-event:hover {
	color : #FFFFFF !important;
  }
  
  /* Inherits background for each event from Schedule. */
  .fc-event .fc-bg {
	z-index : 1 !important;
	background : inherit !important;
	opacity : 0.25 !important;
  }
  
  /* Normal font weight for the time in each event */
  .fc-time-grid-event .fc-time {
	font-weight : normal !important;
  }
  
  /* Apply same opacity to all day events */
  .fc-ltr .fc-h-event.fc-not-end, .fc-rtl .fc-h-event.fc-not-start {
	opacity : 0.65 !important;
	margin-left : 12px !important;
	padding : 5px !important;
  }
  
  /* Apply same opacity to all day events */
  .fc-day-grid-event.fc-h-event.fc-event.fc-not-start.fc-end {
	opacity : 0.65 !important;
	margin-left : 12px !important;
	padding : 5px !important;
  }
  
  .fc-time-grid .fc-slats td {
	height : auto;
  }
  
  .fc-ltr .fc-axis {
	padding : 10px;
  }
  
  .fc-nonbusiness {
	background : #D7E0EA;
  }
  
  .fc-events-container {
	padding : 0 10px;
	border : 1px solid #A2B8CD;
	background : #F4F5FA;
	text-align : left;
  }
  
  .fc-events-container .fc-event {
	padding : 5px;
	margin : 10px 0;
	}
	
  .rbc-calendar .rbc-time-view {
	overflow: auto;
  }

	.react-datepicker-wrapper,
	.react-datepicker__input-container,
	.react-datepicker__input-container input {
		display: block;
		width: 100%;
	}